import React, { useEffect, useState } from "react";
import styles from "./BookingDetails.module.css";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import NavBar from "../../components/NavBar/Navbar.js";
import Arrow from "../../components/Arrow/Arrow.js";
import moment from "moment";

import { db } from "../../firebase.js";
import { getAuth } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";

const DetailEntry = ({ name, value }) => {
  return (
    <div className={styles.detailEntry}>
      <p>{name}</p>
      <p className={styles.detailEntryValue}>{value}</p>
    </div>
  );
};

const BookingDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isConfirmation = location.state ? location.state.isConfirmation : false;
  let isChef = false;
  const currUser = getAuth().currentUser?.uid;

  const { bookingID } = useParams();

  const [bookingInfo, setBookingInfo] = useState({
    address: "",
    allergies: "",
    bookingID: "",
    chefName: "",
    custName: "",
    created: "",
    date: "",
    price: "",
    partySize: "",
  });

  useEffect(() => {
    const getBookingInfo = async () => {
      try {
        const checkoutSessionQuery = await getDocs(
          query(collection(db, "Bookings"), where("__name__", "==", bookingID))
        );

        checkoutSessionQuery.forEach((doc) => {
          const data = doc.data();
          const address = `${data.address.line1} ${
            data.address.line2 ? data.address.line2 : " "
          }${data.address.city} ${data.address.state} ${
            data.address.postal_code
          }`;

          if (currUser == data.chef.id) {
            isChef = true;
          }
          setBookingInfo({
            address: address,
            allergies: data.allergies,
            bookingID: doc.id,
            chefName: data.chef.name,
            custName: data.customer.name,
            created: data.created,
            date: moment(new Date(data.date.seconds * 1000)).format(
              "MMMM Do YYYY, h:mm a"
            ),
            price: data.price,
            partySize: data.partySize,
          });
        });
      } catch (e) {
        console.log("ERROR=", e);
      }
    };
    getBookingInfo();
  }, []);

  return (
    <div className={styles.page}>
      <div className={styles.navbarContainer}>
        <NavBar alt={false} float={false} base={false} />
      </div>
      <div className={styles.cardContainer}>
        <div className={styles.card}>
          {!isConfirmation && (
            <div className={styles.backButton}>
              <Arrow
                showLeft
                onClick={() => navigate("/dashboard")}
                showRight={false}
                alt={false}
                large={false}
              />
            </div>
          )}
          <p className={styles.cardTitle}>
            {isConfirmation
              ? "sweet! your booking is confirmed!"
              : `your booking with ${
                  isChef ? bookingInfo.custName : bookingInfo.chefName
                }`}
          </p>
          <div className={styles.blob}>
            <p className={styles.blobTitle}>details</p>
            <div className={styles.entries}>
              <DetailEntry name="booking ID:" value={bookingInfo.bookingID} />
              <DetailEntry name="chef:" value={bookingInfo.chefName} />
              <DetailEntry name="customer" value={bookingInfo.custName} />
              <DetailEntry name="party size:" value={bookingInfo.partySize} />
              <DetailEntry name="date:" value={bookingInfo.date} />
              <DetailEntry name="price:" value={"$ " + bookingInfo.price} />
              <DetailEntry name="location:" value={bookingInfo.address} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;
