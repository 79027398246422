import React, { useState, useEffect, useRef, useCallback } from "react";
import "./Dropdown.css";
import dropdown_small from "./assets/dropdown_small.svg";
import dropdown_small_alt from "./assets/dropdown_small_alt.svg";

const Dropdown = ({ buttonText, dropdownContent, alt }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleScroll = useCallback(() => {
    closeDropdown();
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        buttonRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        closeDropdown();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
      document.addEventListener("scroll", handleScroll);

      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
        document.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isOpen, handleScroll]);

  return (
    <div className={`dropdown-container${alt ? "-alt" : ""}`}>
      <button
        ref={buttonRef}
        className={`dropdown-button${alt ? "-alt" : ""}`}
        onClick={toggleDropdown}
      >
        {buttonText}
        <img src={alt ? dropdown_small_alt : dropdown_small} alt="" />
      </button>
      {isOpen && (
        <div
          ref={dropdownRef}
          className={`dropdown-content${alt ? "-alt" : ""}`}
        >
          {dropdownContent}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
