import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfigDev = {
  apiKey: "AIzaSyAo8vmhw6I-FCZbVg_Wi4oj1CE6SfEx59Q",
  authDomain: "basil-dev-b75fb.firebaseapp.com",
  projectId: "basil-dev-b75fb",
  storageBucket: "basil-dev-b75fb.appspot.com",
  messagingSenderId: "64401045149",
  appId: "1:64401045149:web:c98d8a24c38269c0acc674",
  measurementId: "G-DN65CB3KHK",
};

const firebaseConfigProd = {
  apiKey: "AIzaSyD71cw-jf1Vblwrk6_DyzVGRkdsfAF3xyQ",
  authDomain: "basil-rosemary.firebaseapp.com",
  projectId: "basil-rosemary",
  storageBucket: "basil-rosemary.appspot.com",
  messagingSenderId: "899449570596",
  appId: "1:899449570596:web:4bb0311021355aa7ba1fb1",
  measurementId: "G-QLFHQZ6YCE",
};

// 0 for DEV, 1 for PROD
const env = 1;

// Initialize Firebase
const app = initializeApp(env ? firebaseConfigProd : firebaseConfigDev);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const googleProvider = new GoogleAuthProvider();
