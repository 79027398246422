import React, { useState } from "react";
import styles from "../Calendar/Calendar.module.css";
import DatePicker from "react-datepicker";
import "../Calendar/react-datepicker.css";
import Button from "../Button/Button.js";
import TimePill from "../TimePill/TimePill.js";

const AvailabilityCalendar = (props) => {
  // Hook for selectedDate
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [timeSelected, setTimeSelected] = useState(false);
  const [selectedTimes, setSelectedTimes] = useState([]);

  const timeArray = [
    "00:00",
    "00:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30",
  ];

  const timePills = timeArray.map((time) => {
    const isSelected = selectedTimes.includes(time);

    const handleClick = () => {
      if (isSelected) {
        setSelectedTimes((currentTimes) =>
          currentTimes.filter((currTime) => currTime != time)
        );
      } else {
        setSelectedTimes((currentTimes) => [...currentTimes, time]);
      }
      setTimeSelected(true);
    };

    return (
      <TimePill
        time={time}
        onClick={() => handleClick()}
        isClicked={isSelected}
      />
    );
  });

  const handleChange = (date) => {
    setSelectedDate(date);
    setSelectedTimes([]);
    setTimeSelected(false);
  };

  const handleSubmit = () => {
    const availabilityArray = selectedTimes.map((time) => {
      const splitTime = time.split(":");
      const dateFromTime = new Date(
        selectedDate.setHours(Number(splitTime[0]), Number(splitTime[1]))
      );
      dateFromTime.setSeconds(0);
      return { start: dateFromTime };
    });
    setSelectedTimes([]);
    console.log(availabilityArray);
    // TODO: Use the chefID prop and add availabilityArray to the chef's availability in the database.
    // FYI, how availability works right now is the chef selects the times for each day and saves.
    // The chef must save before they switch days. Sorry :(
    // PS. Duplication is not handled here so when you add it to the database, please make sure it isn't already there!
    // PPS. I'm so tired.
  };

  return (
    <div className={styles.main}>
      <DatePicker
        selected={selectedDate}
        onChange={(date) => handleChange(date)}
        inline
        minDate={new Date()}
      />
      <div className={styles.timeContainer}>
        <p className={styles.timeTitle}>
          times available for {selectedDate.toISOString().split("T")[0]}
        </p>
        <div className={styles.timePills}>{timePills}</div>
        <div className={styles.nextButtonContainer}>
          <Button
            dropdown={false}
            alt={false}
            large={false}
            long={true}
            text={"save times"}
            onClick={() =>
              timeSelected ? handleSubmit() : console.log("SELECT A TIME")
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AvailabilityCalendar;
