import React from "react";
import styles from "./TextField.module.css";

const TextField = (props) => {
  return (
    <div className={styles.TextField}>
      <label className={styles.LabelText}>{props.label}</label>
      <input
        className={styles.InputBox}
        type={props.type}
        value={props.value}
        placeholder={props.placeholder}
        name={props.name}
        onChange={props.onChange}
        required={props.required}
        style={{
          width: props.width ? props.width : "19rem",
          border: props.error ? "2px solid var(--color-flame)" : "",
        }}
      />
    </div>
  );
};

export default TextField;
