import React from "react";
import { useNavigate } from "react-router-dom";
import "./Logo.css";
import logo_main from "./assets/logo_green.svg";
import logo_alt from "./assets/logo_beige.svg";

const Logo = (props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
    window.scroll(0, 0);
  };

  return (
    <img
      className="logo"
      src={props.alt ? logo_alt : logo_main}
      onClick={handleClick}
    />
  );
};

export default Logo;
