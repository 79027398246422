import React from "react";
import "./Profile.css";
import profile_main from "./assets/account_green.svg";
import profile_alt from "./assets/account_transparent.svg";
import { useNavigate } from "react-router-dom";

const Profile = (props) => {
  const navigate = useNavigate();

  let display;
  if (!props.alt) {
    display = profile_main;
  } else {
    display = profile_alt;
  }

  return (
    <img
      className="profile"
      src={display}
      onClick={() => navigate("/dashboard")}
      alt="profile"
    />
  );
};

export default Profile;
