import React from "react";
import styles from "./ChefCard.module.css";

import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";

import Button from "../Button/Button.js";
import star from "./assets/star.svg";
import { Skeleton } from "@mui/material";

const ChefCard = (props) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (getAuth().currentUser) {
      props.onPickDate();
    } else {
      navigate("/login");
    }
  };

  const name = props.chef.name ? props.chef.name : "null";
  const numServed = props.chef.numServed ? props.chef.numServed : 0;
  const cuisine = props.chef.cuisine ? props.chef.cuisine : "null";
  const availability = props.chef.availability
    ? props.chef.availability[0].start.substring(0, 10)
    : "null";
  const pricePerPerson = props.chef.pricePerPerson
    ? props.chef.pricePerPerson
    : 0;

  const profilePicture =
    props.chef.profilePicture !== undefined ? (
      <div
        className={styles.profilePicture}
        style={{ backgroundImage: `url(${props.chef.profilePicture})` }}
      />
    ) : (
      <Skeleton
        variant="circular"
        height={"9.125rem"}
        width={"9.125rem"}
        sx={{ marginTop: "2.25rem" }}
      />
    );

  const galleryPictures =
    props.chef.galleryPictures !== undefined ? (
      <div className={styles.gallery}>
        <div
          className={styles.main}
          style={{ backgroundImage: `url(${props.chef.galleryPictures[0]})` }}
        ></div>
        <div className={styles.subgallery}>
          <div
            className={styles.rest}
            style={{ backgroundImage: `url(${props.chef.galleryPictures[1]})` }}
          ></div>
          <div
            className={styles.rest}
            style={{ backgroundImage: `url(${props.chef.galleryPictures[2]})` }}
          ></div>
        </div>
      </div>
    ) : (
      <div className={styles.gallery}>
        <Skeleton variant="rounded" height={"12.9rem"} width={"9.563rem"} />
        <div className={styles.subgallery}>
          <Skeleton variant="rounded" height={"6.20rem"} width={"9.572rem"} />
          <Skeleton variant="rounded" height={"6.20rem"} width={"9.572rem"} />
        </div>
      </div>
    );

  return (
    <div className={styles.container} onClick={props.onClick}>
      {profilePicture}
      <h1 className={styles.name}>{name}</h1>
      <div className={styles.subtitle}>
        <img src={star} alt="star" />
        <p>0 (0 reviews) • {numServed} served</p>
      </div>
      <div className={styles.textContainer}>
        <p className={styles.contextText}>cuisine: </p>
        <p className={styles.contextText} style={{ color: "#E4572E" }}>
          {cuisine}
        </p>
      </div>
      <div className={styles.textContainer}>
        <p className={styles.contextText}>earliest availability: </p>
        <p className={styles.contextText} style={{ color: "#E4572E" }}>
          {availability}
        </p>
      </div>
      {galleryPictures}
      <p className={styles.price}>${pricePerPerson} per person</p>
      <Button text="pick date" onClick={handleButtonClick}></Button>
    </div>
  );
};

export default ChefCard;
