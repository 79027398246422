import React from "react";
import styles from "./ChefBox.module.css";

import Menu from "../Menu/Menu.js";
import Arrow from "../Arrow/Arrow.js";
import Button from "../Button/Button.js";
import star from "../ChefCard/assets/star.svg";
import { Skeleton } from "@mui/material";

const ChefBox = (props) => {
  const name = props.chef.name ? props.chef.name : "null";
  const bio = props.chef.bio ? props.chef.bio : "null";
  const numServed = props.chef.numServed ? props.chef.numServed : 0;
  const cuisine = props.chef.cuisine ? props.chef.cuisine : "null";
  const availability = props.chef.availability
    ? props.chef.availability[0].start.substring(0, 10)
    : "null";
  const pricePerPerson = props.chef.pricePerPerson
    ? props.chef.pricePerPerson
    : 0;
  const dateJoined = props.chef.dateJoined ? props.chef.dateJoined : 2023;

  const profilePicture =
    props.chef.profilePicture !== undefined ? (
      <div
        className={styles.chefCardProfile}
        style={{ backgroundImage: `url(${props.chef.profilePicture})` }}
      />
    ) : (
      <Skeleton
        variant="circular"
        height={"9.125rem"}
        width={"9.125rem"}
        sx={{ marginTop: "2.25rem" }}
      />
    );
  const galleryPictures =
    props.chef.galleryPictures !== undefined ? (
      <div className={styles.chefCardGallery}>
        <div
          className={styles.chefCardMainPhoto}
          style={{ backgroundImage: `url(${props.chef.galleryPictures[0]})` }}
        ></div>
        <div className={styles.chefCardSubgallery}>
          <div
            className={styles.chefCardSecondaryPhotos}
            style={{ backgroundImage: `url(${props.chef.galleryPictures[1]})` }}
          ></div>
          <div
            className={styles.chefCardSecondaryPhotos}
            style={{ backgroundImage: `url(${props.chef.galleryPictures[2]})` }}
          ></div>
        </div>
      </div>
    ) : (
      <div className={styles.chefCardGallery}>
        <Skeleton variant="rounded" height={"12.9rem"} width={"9.563rem"} />
        <div className={styles.chefCardSubgallery}>
          <Skeleton variant="rounded" height={"6.20rem"} width={"9.572rem"} />
          <Skeleton variant="rounded" height={"6.20rem"} width={"9.572rem"} />
        </div>
      </div>
    );

  return (
    <div className={styles.chefBoxContainer}>
      <div className={styles.chefBox}>
        {/* First Column - Chef Card */}
        <div className={styles.chefCardContainer}>
          <div className={styles.arrow}>
            <Arrow showLeft onClick={props.backOnClick}></Arrow>
          </div>
          {/* Chef Photo */}
          {profilePicture}
          {/* Chef Name */}
          <h1 className={styles.chefCardName}>{name}</h1>
          {/* Chef Information */}
          <div className={styles.chefCardTextContainer}>
            <p className={styles.chefCardContextText}>cuisine: </p>
            <p
              className={styles.chefCardContextText}
              style={{ color: "#E4572E" }}
            >
              {cuisine}
            </p>
          </div>
          <div className={styles.chefCardTextContainer}>
            <p className={styles.chefCardContextText}>
              earliest availability:{" "}
            </p>
            <p
              className={styles.chefCardContextText}
              style={{ color: "#E4572E" }}
            >
              {availability}
            </p>
          </div>
          {/* Chef Gallery */}
          {galleryPictures}
          <Button text="pick date" onClick={props.fwdOnClick}></Button>
        </div>

        {/* Second Column - Menu */}
        <div>
          <Menu
            menuText={props.chef.menuText}
            isMenuText={props.chef.isMenuText}
            menuPic={props.chef.menuPic}
            pricePerPerson={pricePerPerson}
          />
        </div>

        {/* Third Column - Bio and Reviews */}
        <div className={styles.aboutAndReviewContainer}>
          <div>
            <h1 className={styles.aboutAndReviewHeader}>about</h1>
            <p
              className={styles.chefCardContextText}
              style={{ color: "#E4572E", fontSize: 18 }}
            >
              joined {dateJoined}
            </p>
            <p className={styles.bio}>{bio}</p>
          </div>
          <div>
            <div className={styles.reviewHeader}>
              <h1 className={styles.aboutAndReviewHeader}>reviews</h1>
              <div className={styles.reviewSubheader}>
                <img src={star} alt="star" style={{ paddingRight: "0.5rem" }} />
                <p className={styles.reviewText}>0 (0 reviews)</p>
              </div>
            </div>
            <div className={styles.reviewScoreboard}>
              <div className={styles.metricContainer}>
                <p className={styles.reviewSubtext}>food</p>
                <p className={styles.reviewSubtext}>service</p>
                <p className={styles.reviewSubtext}>cleanliness</p>
              </div>
              <div className={styles.barFlexContainer}>
                <div className={styles.barContainer}>
                  <hr
                    className={styles.reviewBar}
                    style={{ width: `${(props.FoodRating / 5.0) * 100}%` }}
                  />
                </div>
                <div className={styles.barContainer}>
                  <hr
                    className={styles.reviewBar}
                    style={{ width: `${(props.ServiceRating / 5.0) * 100}%` }}
                  />
                </div>
                <div className={styles.barContainer}>
                  <hr
                    className={styles.reviewBar}
                    style={{
                      width: `${(props.CleanlinessRating / 5.0) * 100}%`,
                    }}
                  />
                </div>
              </div>
              <div className={styles.metricScoreContainer}>
                <p className={styles.reviewSubtext}>0</p>
                <p className={styles.reviewSubtext}>0</p>
                <p className={styles.reviewSubtext}>0</p>
              </div>
            </div>
            <div className={styles.recentReviews}>
              <p className={styles.reviewText} style={{ color: "#E4572E" }}>
                most recent review
              </p>
              <div className={styles.recentReviewContainer}>
                <p className={styles.reviewSubtext}>no reviews yet</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChefBox;
