import React, { useState, useEffect } from "react";
import styles from "./EditProfile.module.css";

import Button from "../../components/Button/Button.js";
import TextField from "../../components/TextField/TextField.js";
import Navbar from "../../components/NavBar/Navbar.js";
import TextArea from "../../components/TextArea/TextArea.js";
import UploadFile from "../../components/UploadFile/UploadFile.js";
import Snackbar from "@mui/material/Snackbar";
import CircularProgress from "@mui/material/CircularProgress";

import { collection, getDocs, query, where, setDoc } from "firebase/firestore";
import { db } from "../../firebase.js";
import { getAuth } from "firebase/auth";

const initialFormState = {
  name: "",
  cuisine: "",
  bio: "",
  // price: "",
};

const EditProfile = () => {
  const uid = getAuth().currentUser?.uid;
  const isOnboarding = !!getAuth().currentUser.name;
  const [userInfo, setUserInfo] = useState(null);
  const [chefInfo, setChefInfo] = useState(null);

  const [formState, setFormState] = useState(initialFormState);
  const [isChef, setIsChef] = useState(null);
  const [email, setEmail] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const userDocs = await getDocs(
          query(collection(db, "Users"), where("__name__", "==", uid))
        );
        const userData = userDocs.docs[0].data();
        setUserInfo(userDocs);
        setIsChef(userData.isChef);
        setEmail(userData.email);
        setFormState({ name: userData.name });

        console.log(userDocs);

        if (isChef) {
          const chefDocs = await getDocs(
            query(collection(db, "Chefs"), where("email", "==", email))
          );
          const chefData = chefDocs.docs[0].data();
          setChefInfo(chefDocs);
          setFormState({
            ...formState,
            cuisine: chefData.cuisine,
            bio: chefData.bio,
            // price: chefData.pricePerPerson,
          });
          console.log(chefDocs);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getUserInfo();
  }, [email, uid]);

  const handleButtonClick = async () => {
    if (isChef) {
      try {
        await setDoc(
          chefInfo.docs[0].ref,
          {
            name: formState.name,
            cuisine: formState.cuisine,
            bio: formState.bio,
            // pricePerPerson: formState.price,
          },
          { merge: true }
        );
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        await setDoc(
          userInfo.docs[0].ref,
          { name: formState.name },
          { merge: true }
        );
      } catch (e) {
        console.log(e);
      }
    }
    setTimeout(() => {
      setShowSnackbar(true);
    }, 350);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
    window.alert("Your changes have been saved!");
  };

  return (
    <div className={styles.page}>
      <div className={styles.navbarContainer}>
        <Navbar alt={false} float={false} base={false} />
      </div>
      <div className={styles.contentContainer}>
        <h1 className={styles.banner}>about you</h1>
        <form
          onSubmit={handleSubmit}
          className={styles.form}
          style={{ flexDirection: "column" }}
        >
          <div className={styles.fields}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.8rem",
              }}
            >
              <TextField
                required
                label="name"
                type="string"
                name="name"
                value={formState.name}
                onChange={handleInputChange}
              />
              {isChef && (
                <>
                  <TextField
                    required
                    label="cuisine"
                    type="string"
                    name="cuisine"
                    value={formState.cuisine}
                    onChange={handleInputChange}
                  />
                  {/* <TextField
                    required
                    label="price per person (in $)"
                    type="number"
                    name="price"
                    value={formState.price}
                    onChange={handleInputChange}
                  /> */}
                </>
              )}
            </div>
            {isChef && (
              <TextArea
                className={styles.textArea}
                required
                label="bio"
                type="string"
                name="bio"
                value={formState.bio}
                placeholder={"tell us about you!"}
                styles={{ width: "19rem" }}
                onChange={handleInputChange}
              />
            )}
            {isChef && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.8rem",
                }}
              >
                <UploadFile email={email} value="menuPic" label="your menu" />
                <UploadFile
                  email={email}
                  value="profilePicture"
                  label="your profile picture"
                />
              </div>
            )}
          </div>
          <div className={styles.Button}>
            <Button
              onClick={handleButtonClick}
              text={isOnboarding ? "continue" : "save"}
              dropdown={false}
              alt={false}
              large={false}
              long={false}
            ></Button>
          </div>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={showSnackbar}
            onClose={handleCloseSnackbar}
            message="saving..."
            autoHideDuration={2000}
            action={
              <>
                <CircularProgress size="2rem" />
              </>
            }
          />
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
