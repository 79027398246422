import React, { useState } from "react";
import styles from "./Calendar.module.css";
import DatePicker from "react-datepicker";
import "./react-datepicker.css";

import TimePill from "../TimePill/TimePill.js";
import NextButton from "../NextButton/NextButton.js";

const Calendar = (props) => {
  // Helper functions
  const getKeyFromDate = (someDate) => {
    const date = someDate.getDate().toString();
    const month = someDate.getMonth().toString();
    const year = someDate.getFullYear().toString();

    const fullDate = month.concat(date, year);
    return fullDate;
  };
  const getTimeFromDate = (someDate) => {
    const hour = someDate.getHours().toString();
    const minute = someDate.getMinutes().toString();

    const fullTime = hour.concat(":", minute, "0");
    return fullTime;
  };
  const minDate = (dates) => new Date(Math.min(...dates));

  // Dictionary to store the times available for a given date
  let availableDates = [];
  const dateTimeDictionary = {};

  // Loop through availability and add to list of available dates, as well as dictionary
  for (const dateAndTime of props.availability) {
    const todayDate = new Date();
    const someDate = new Date(dateAndTime.start);

    if (someDate > todayDate) {
      const time = getTimeFromDate(someDate);
      const fullDate = getKeyFromDate(someDate);
      fullDate in dateTimeDictionary
        ? dateTimeDictionary[fullDate].push(time)
        : (dateTimeDictionary[fullDate] = [time]);

      availableDates.push(someDate);
    } else {
      continue;
    }
  }

  // Hook for selectedDate
  const [selectedDate, setSelectedDate] = useState(minDate(availableDates));
  const [timeSelected, setTimeSelected] = useState(false);

  // Initalize timePills based on selectedDate
  const timeArray = dateTimeDictionary[getKeyFromDate(selectedDate)];
  const pillState = timeArray.map((someTime) => {
    const entry = { time: someTime, state: false };
    return entry;
  });
  const [pillStates, setPillStates] = useState(pillState);
  const timePills = pillStates.map((pillState) => {
    const handleClick = () => {
      const newStates = pillStates.map((state) => {
        if (state.time !== pillState.time) {
          return { ...state, state: false };
        } else {
          return { ...state, state: !state.state };
        }
      });
      const splitTime = pillState.time.split(":");

      setPillStates(newStates);
      setTimeSelected(true);
      selectedDate.setHours(Number(splitTime[0]), Number(splitTime[1]), 0, 0);
    };
    return (
      <TimePill
        time={pillState.time}
        onClick={() => handleClick()}
        isClicked={pillState.state}
      />
    );
  });

  const handleChange = (date) => {
    setSelectedDate(date);
    const timeArray = dateTimeDictionary[getKeyFromDate(selectedDate)];
    const pillState = timeArray.map((someTime) => {
      const entry = { time: someTime, state: false };
      return entry;
    });
    setPillStates(pillState);
    setTimeSelected(false);
  };

  return (
    <div className={styles.main}>
      <DatePicker
        selected={selectedDate}
        onChange={(date) => handleChange(date)}
        inline
        minDate={minDate(availableDates)}
        includeDates={availableDates}
      />
      <div className={styles.timeContainer}>
        <p className={styles.timeTitle}>
          times available for {selectedDate.toISOString().split("T")[0]}
        </p>
        <div className={styles.timePills}>{timePills}</div>
        <div className={styles.nextButtonContainer}>
          <NextButton
            onClick={() =>
              timeSelected
                ? props.nextButton(selectedDate)
                : console.log("Select a time motherfucker")
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Calendar;
