import React from "react";
import styles from "./EditAvailability.module.css";
import Navbar from "../../components/NavBar/Navbar.js";
import AvailabilityCalendar from "../../components/AvailabilityCalendar/AvailabilityCalendar.js";

const EditAvailability = () => {
  return (
    <div className={styles.page}>
      <div className={styles.navbarContainer}>
        <Navbar alt={false} float={false} base={false} />
      </div>
      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <p className={styles.cardTitle}>when would you like to cook?</p>
          <AvailabilityCalendar
            chefID={123344}
            nextButton={() => console.log()}
          />
        </div>
      </div>
    </div>
  );
};

export default EditAvailability;
