import React from "react";
import Button from "../Button/Button.js";
import styles from "./AddressForm.module.css";

import { AddressElement } from "@stripe/react-stripe-js";

const AddressForm = (props) => {
  let address;

  return (
    <form>
      <AddressElement
        onChange={(event) => {
          if (event.complete) {
            address = event.value.address;
          }
        }}
        options={{
          mode: "shipping",
          allowedCountries: ["US"],
          autocomplete: {
            mode: "google_maps_api",
            apiKey: "YOUR_API_KEY_HERE",
          },
        }}
      />
      <div className={styles.submitButton}>
        <Button text="submit" onClick={() => props.handleClick(address)} />
      </div>
    </form>
  );
};

export default AddressForm;
