import React, { useState } from "react";
import styles from "./BookingFlow.module.css";
import { useLocation } from "react-router-dom";

import Calendar from "../../components/Calendar/Calendar.js";
import NavBar from "../../components/NavBar/Navbar.js";
import Arrow from "../../components/Arrow/Arrow.js";
import AddressForm from "../../components/AddressForm/AddressForm.js";
import TextArea from "../../components/TextArea/TextArea.js";
import Button from "../../components/Button/Button.js";
import PartySize from "../PartySize/PartySize";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";

import { db } from "../../firebase.js";
import { getAuth } from "firebase/auth";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
  addDoc,
} from "firebase/firestore";

import { v4 as uuidv4 } from "uuid";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const BookingFlow = () => {
  const StripePubKey =
    "pk_live_51MueOjJvzvTWEv9m8DjxlALTDlse5qZFVOfelsrRc0g3K8BvUxcWI2sQ0afge6JR9ZOzLCfP5P9vJ9YcnckbOnMu00eq7OyKL7";

  const checkOut = async () => {
    const userId = getAuth().currentUser?.uid;
    let chefPriceId;
    let chefEmail;

    const chefIdQuery = await getDocs(
      query(collection(db, "Chefs"), where("__name__", "==", chefId))
    );

    chefIdQuery.forEach((doc) => {
      const data = doc.data();
      chefPriceId = data.default_price;
      chefEmail = data.email;
    });

    const addCheckoutSession = async () => {
      const colRef = collection(db, "Users", userId, "checkout_sessions");
      let uuid = uuidv4();
      uuid = uuid.replaceAll("-", "");
      uuid = uuid.substring(0, 20);
      const docRef = await addDoc(colRef, {
        bookingID: uuid,
        mode: "payment",
        quantity: partySize,
        date: date,
        price: chefPriceId,
        success_url: `${window.location.origin}/booking/${uuid}`,
        cancel_url: window.location.origin,
        address: address,
        chef: chefId,
        chefName: chefName,
        chefEmail: chefEmail,
        allergies: textValue.length ? textValue : "NONE",
      });

      onSnapshot(docRef, async (snap) => {
        const { error, sessionId } = snap.data();
        if (error) {
          alert(error.message);
        }

        if (sessionId) {
          const stripe = await loadStripe(StripePubKey);
          stripe?.redirectToCheckout({ sessionId });
        }
      });
    };
    addCheckoutSession();
  };

  const location = useLocation();

  const { chefId, chefName, availability } = location.state;

  const stripe = loadStripe(StripePubKey);

  const [date, selectDate] = useState(null);
  const [partySize, selectPartySize] = useState(0);
  const [address, setAddress] = useState("");
  const [textValue, setTextValue] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleCloseSnackbar = () => setShowSnackbar(false);

  if (!date) {
    return (
      <div className={styles.page}>
        <div className={styles.navbarContainer}>
          <NavBar alt={false} float={false} state="isLoggedIn" />
        </div>
        <div className={styles.cardContainer}>
          <div className={styles.card}>
            <p className={styles.cardTitle}>when would you like to dine?</p>
            <Calendar
              availability={availability}
              nextButton={(d) => selectDate(d)}
            />
          </div>
        </div>
      </div>
    );
  } else if (partySize == 0) {
    return (
      <div className={styles.page}>
        <div className={styles.navbarContainer}>
          <NavBar alt={false} float={false} state="isLoggedIn" />
        </div>
        <div className={styles.cardContainer}>
          <div className={styles.card}>
            <div className={styles.backButton}>
              <Arrow
                showLeft
                onClick={() => selectDate(null)}
                showRight={false}
                alt={false}
                large={false}
              />
            </div>
            <PartySize nextButton={(p) => selectPartySize(p)} />
          </div>
        </div>
      </div>
    );
  } else if (!address) {
    return (
      <div className={styles.page}>
        <div className={styles.navbarContainer}>
          <NavBar alt={false} float={false} base={false} />
        </div>
        <div className={styles.cardContainer}>
          <div className={styles.card}>
            <div className={styles.backButton}>
              <Arrow
                showLeft
                onClick={() => selectPartySize(0)}
                showRight={false}
                alt={false}
                large={false}
              />
            </div>
            <p className={styles.cardTitle}>where would you like to dine?</p>
            <Elements stripe={stripe}>
              <AddressForm
                handleClick={(a) => {
                  setAddress(a);
                }}
              />
            </Elements>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.page}>
        <div className={styles.navbarContainer}>
          <NavBar alt={false} float={false} base={false} />
        </div>
        <div className={styles.cardContainer}>
          <div className={styles.card}>
            {!showSnackbar && (
              <div className={styles.backButton}>
                <Arrow
                  showLeft
                  onClick={() => setAddress(null)}
                  showRight={false}
                  alt={false}
                  large={false}
                />
              </div>
            )}
            <p className={styles.cardTitle}>
              any allergies or dietary restrictions?
            </p>
            <TextArea
              required
              cols="50"
              placeholder="Shrimp, peanuts, etc..."
              name="allergies"
              onChange={(event) => {
                setTextValue(event.target.value);
              }}
              rows={""}
              label={""}
              value={textValue}
            ></TextArea>
            <Button
              dropdown={false}
              alt={false}
              large={false}
              long={true}
              text={textValue == "" ? "nope, all good!" : "yes, i do!"}
              onClick={() => {
                checkOut();
                setTimeout(() => {
                  setShowSnackbar(true);
                }, 350);
              }}
            />
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={showSnackbar}
              onClose={handleCloseSnackbar}
              message="Your transaction is being processed."
              action={
                <>
                  <CircularProgress size="2rem" />
                </>
              }
            />
          </div>
        </div>
      </div>
    );
  }
};

export default BookingFlow;
