import React, { useState } from "react";
import styles from "./PartySize.module.css";
import NextButton from "../../components/NextButton/NextButton.js";
import NumberButton from "../../components/NumberButton/NumberButton.js";
import TextField from "../../components/TextField/TextField.js";

const PartySize = (props) => {
  const [buttonStates, setButtonStates] = useState([
    { id: 1, state: false },
    { id: 2, state: false },
    { id: 3, state: false },
    { id: 4, state: false },
    { id: 5, state: false },
    { id: 6, state: false },
  ]);
  const [textValue, setTextValue] = useState("");
  const [selected, setSelected] = useState(0);

  const handleClick = (buttonStates, buttonID) => {
    const newStates = buttonStates.map((state) => {
      if (state.id === buttonID) {
        return { ...state, state: true };
      } else {
        return { ...state, state: false };
      }
    });

    setButtonStates(newStates);
    setTextValue("");
    setSelected(buttonID);
  };
  const handleChange = (e) => {
    setTextValue(e.target.value);
    setSelected(Number(e.target.value));
    setButtonStates([
      { id: 1, state: false },
      { id: 2, state: false },
      { id: 3, state: false },
      { id: 4, state: false },
      { id: 5, state: false },
      { id: 6, state: false },
    ]);
  };

  const numberButtons = buttonStates.map((state) => {
    return (
      <NumberButton
        number={state.id}
        isClicked={state.state}
        onClick={() => handleClick(buttonStates, state.id)}
      />
    );
  });

  return (
    <div className={styles.main}>
      <p className={styles.cardTitle}>how many people will be dining?</p>
      <div className={styles.contentContainer}>
        <div className={styles.buttonContainer}>{numberButtons}</div>
        <p className={styles.subtitle}>
          <span style={{ color: "var(--color-flame)" }}>got a crowd? </span>
          <span style={{ color: "var(--color-sap)" }}>
            enter your party size!
          </span>
        </p>
        <TextField
          width="10.6rem"
          label={""}
          type="number"
          placeholder="7 or more"
          value={textValue}
          onChange={(e) => handleChange(e)}
          name={""}
          required={false}
        />
        <div className={styles.nextButtonContainer}>
          <NextButton onClick={() => props.nextButton(selected)} />
        </div>
      </div>
    </div>
  );
};

export default PartySize;
