import React, { useState, useEffect, useRef } from "react";
import styles from "./ChefSearch.module.css";
import { useNavigate } from "react-router-dom";

import ChefCard from "../../components/ChefCard/ChefCard.js";
import ChefBox from "../../components/ChefBox/ChefBox.js";
import Navbar from "../../components/NavBar/Navbar.js";

import { db } from "../../firebase.js";
import { collection, getDocs } from "firebase/firestore";

const ChefSearch = () => {
  const [chefList, setChefList] = useState([]);
  const [selectedChef, setSelectedChef] = useState(null);

  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);
  const handleMouseWheel = (event) => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += event.deltaY;
    }
  };

  const navToBooking = (chefId, chefName, availability) => {
    return () => {
      navigate("/booking", {
        state: {
          chefId: chefId,
          chefName: chefName,
          availability: availability,
        },
      });
      console.log(availability);
    };
  };

  const handleClick = (id) => {
    chefList.forEach((chef) => {
      if (chef.id === id) {
        setSelectedChef(chef);
      }
    });
  };

  // Gets ALL chef information for the chef card
  useEffect(() => {
    const getChefList = async () => {
      try {
        const data = await getDocs(collection(db, "Chefs"));
        const chefData = await Promise.all(
          data.docs.map(async (doc) => {
            const chef = doc.data();
            return {
              ...chef,
              id: doc.id,
            };
          })
        );
        setChefList(chefData);
      } catch (err) {
        console.log(err);
      }
    };
    getChefList();
  }, []);

  return (
    <div className={styles.page}>
      <Navbar alt={false} float={false} base={false} />
      {selectedChef ? (
        <div className={styles.cardContainer}>
          <ChefBox
            chef={selectedChef}
            backOnClick={() => setSelectedChef(null)}
            fwdOnClick={navToBooking(
              selectedChef?.id,
              selectedChef?.name,
              selectedChef?.availability
            )}
          ></ChefBox>
        </div>
      ) : (
        <div className={styles.cardContainer}>
          <div
            className={styles.cards}
            ref={scrollContainerRef}
            onWheel={handleMouseWheel}
          >
            {chefList.map((chef) => {
              return (
                <ChefCard
                  chef={chef}
                  onPickDate={navToBooking(
                    chef?.id,
                    chef?.name,
                    chef?.availability
                  )}
                  onClick={() => handleClick(chef.id)}
                ></ChefCard>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
export default ChefSearch;
