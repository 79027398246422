import React, { useState } from "react";
import styles from "./TextArea.module.css";

const TextArea = (props) => {
  return (
    <div className={styles.TextArea}>
      <label className={styles.LabelText}>{props.label}</label>
      <textarea
        className={props.className ? props.className : styles.InputBox}
        rows={props.rows}
        cols={props.cols}
        value={props.value}
        placeholder={props.placeholder}
        name={props.name}
        onChange={props.onChange}
        required={props.required}
        style={{
          border: props.error ? "2px solid var(--color-flame)" : "",
        }}
      />
    </div>
  );
};

export default TextArea;
