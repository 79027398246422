import "./App.css";
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "./Context.js";

import EditProfile from "./containers/EditProfile/EditProfile.js";
import ChefSearch from "./containers/ChefSearch/ChefSearch.js";
import ContactUs from "./containers/ContactUs/ContactUs.js";
import Landing from "./containers/Landing/Landing.js";
import Login from "./containers/Login/Login.js";
import Manifesto from "./containers/Manifesto/Manifesto.js";
import SignUp from "./containers/SignUp/SignUp.js";
import Dashboard from "./containers/Dashboard/Dashboard.js";
import ErrorPage from "./containers/ErrorPage/ErrorPage.js";
import BookingFlow from "./containers/BookingFlow/BookingFlow.js";
import BookingDetails from "./containers/BookingDetails/BookingDetails.js";
import EditAvailability from "./containers/EditAvailability/EditAvailability.js";
import ResetPassword from "./containers/ResetPassword/ResetPassword.js";

function App() {
  const user = useAuth();

  return (
    <Routes>
      <Route exact path="/" element={<Landing />} />
      <Route exact path="/chefsearch" element={<ChefSearch />} />
      <Route exact path="/contactus" element={<ContactUs />} />
      <Route exact path="/manifesto" element={<Manifesto />} />
      <Route
        exact
        path="/booking"
        element={user ? <BookingFlow /> : <Login />}
      />
      <Route exact path="/booking/:bookingID?" element={<BookingDetails />} />
      <Route
        exact
        path="/edit/profile"
        element={user ? <EditProfile /> : <Navigate to="/login" />}
      />
      <Route
        exact
        path="/edit/availability"
        element={user ? <EditAvailability /> : <Navigate to="/login" />}
      />
      <Route
        exact
        path="/dashboard"
        element={user ? <Dashboard /> : <Navigate to="/login" />}
      />
      <Route
        exact
        path="/login"
        element={user ? <Navigate to="/dashboard" /> : <Login />}
      />
      <Route
        exact
        path="/signup"
        element={user ? <Navigate to="/dashboard" /> : <SignUp />}
      />
      <Route
        exact
        path="/resetpassword"
        element={user ? <Navigate to="/dashboard" /> : <ResetPassword />}
      />
      <Route exact path="*" element={<Navigate to="/login" />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
