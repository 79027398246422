import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Dashboard.module.css";

import NavBar from "../../components/NavBar/Navbar.js";
import Button from "../../components/Button/Button.js";
import Avatar from "@mui/material/Avatar";
import moment from "moment";

import { useAuth } from "../../Context.js";
import { db, auth } from "../../firebase.js";
import { signOut } from "firebase/auth";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";

function stringAvatar(name, sz) {
  const nameSplit = name.split(" ");
  return {
    sx: {
      bgcolor: "#E4572E",
      height: sz,
      width: sz,
      fontSize: sz / 3,
      fontFamily: "JosefinSans",
    },
    children: `${nameSplit[0][0]}${nameSplit[1][0]}`,
  };
}

const Dashboard = () => {
  // You can see sample booking array data below. Make sure the data is complete.
  const isChef = false;
  const navigate = useNavigate();
  const [pastBookings, setPastBookings] = useState([]);
  const [upcomingBookings, setUpcomingBookings] = useState([]);

  const user = useAuth();
  const uid = user?.uid;

  useEffect(() => {
    const getBookings = async () => {
      try {
        let pastBookingObjects = [];
        let upcomingBookingObjects = [];
        const stringIsChef = () => {
          return isChef ? "chef" : "customer";
        };
        const bookingsQuery = await getDocs(
          query(
            collection(db, "Bookings"),
            where(stringIsChef() + ".id", "==", uid),
            orderBy("date", "desc")
          )
        );
        bookingsQuery.forEach((doc) => {
          const data = doc.data();
          const bookingDate = new Date(data.date.seconds * 1000);

          const isPast = bookingDate < new Date() ? true : false;
          if (isPast) {
            pastBookingObjects.push({
              bookingID: doc.id,
              chefName: data.chef.name,
              customerName: data.customer.name,
              date: moment(bookingDate).format("MMMM Do YYYY"),
            });
          } else {
            upcomingBookingObjects.push({
              bookingID: doc.id,
              chefName: data.chef.name,
              customerName: data.customer.name,
              date: moment(bookingDate).format("MMMM Do YYYY"),
            });
          }
        });
        setPastBookings(pastBookingObjects);
        setUpcomingBookings(upcomingBookingObjects);
      } catch (e) {
        console.log("ERROR ", e);
      }
    };
    getBookings();
  }, []);

  // This handles horizontal scrolling
  const scrollContainerRef = useRef(null);
  const anotherScrollContainerRef = useRef(null);
  const handleMouseWheel = (event, ref) => {
    if (ref.current) {
      ref.current.scrollLeft += event.deltaY;
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (e) {
      console.log(e.message);
    }
  };

  const BookingBubble = ({ date, name, bookingID }) => {
    const handleClick = () => {
      // TODO: This should direct to the corresponding booking details page based on bookingID.
      // Use the params above and feel free to add more.
      // If you'd prefer to just pass in the booking object itself, do it.
      // BUT DO NOT FORGET TO CHANGE THE NAME BASED ON isChef SO THAT THE BUBBLES HAVE THE RIGHT NAME!!!
      navigate("/booking/" + bookingID);
    };
    return (
      <div className={styles.bookingBubble} onClick={handleClick}>
        <Avatar {...stringAvatar(name, 120)} />
        <div className={styles.bookingBubbleText}>
          <p className={styles.date}>{date}</p>
          <p>with</p>
          <p className={styles.name}>{name}</p>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.page}>
      <div className={styles.navbarContainer}>
        <NavBar alt={false} float={false} base={false} />
      </div>
      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <div className={styles.section}>
            <div className={styles.buttonAndTitle}>
              <p className={styles.cardTitle}>upcoming bookings</p>
              <div className={styles.buttons}>
                <Button
                  dropdown={false}
                  alt={false}
                  large={false}
                  long={true}
                  text={"edit profile"}
                  // TODO: This button should direct to the corresponding user's edit profile page.
                  onClick={() => navigate("/edit/profile")}
                ></Button>
                {isChef && (
                  <Button
                    dropdown={false}
                    alt={false}
                    large={false}
                    long={true}
                    text={"edit availability"}
                    // TODO: This button only appears if the user is a chef.
                    // It should direct to the availability calendar of the corresponding chefID.
                    onClick={() => navigate("/edit/availability")}
                  ></Button>
                )}
                <Button
                  dropdown={false}
                  alt={false}
                  large={false}
                  long={true}
                  text={"log out"}
                  // TODO: This button should direct to the corresponding user's edit profile page.
                  onClick={() => handleLogout()}
                ></Button>
              </div>
            </div>
            {upcomingBookings.length !== 0 ? (
              <div
                className={styles.bookingRow}
                ref={scrollContainerRef}
                onWheel={(e) => handleMouseWheel(e, scrollContainerRef)}
              >
                {upcomingBookings.map((booking) => {
                  return (
                    <BookingBubble
                      key={booking.bookingID}
                      date={booking.date}
                      name={isChef ? booking.customerName : booking.chefName}
                      bookingID={booking.bookingID}
                    />
                  );
                })}
              </div>
            ) : (
              <p className={styles.noBookingText}>
                you have no upcoming bookings!
              </p>
            )}
          </div>
          <div className={styles.section}>
            <p className={styles.cardTitle}>past bookings</p>
            {pastBookings.length !== 0 ? (
              <div
                className={styles.bookingRow}
                ref={anotherScrollContainerRef}
                onWheel={(e) => handleMouseWheel(e, anotherScrollContainerRef)}
              >
                {pastBookings.map((booking) => {
                  return (
                    <BookingBubble
                      key={booking.bookingID}
                      date={booking.date}
                      name={isChef ? booking.customerName : booking.chefName}
                      bookingID={booking.bookingID}
                    />
                  );
                })}
              </div>
            ) : (
              <p className={styles.noBookingText}>you have no past bookings!</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
