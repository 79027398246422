import React, { useState, useEffect } from "react";
import "../NavBar/Navbar.css";
import Logo from "../Logo/Logo.js";
import Button from "../Button/Button.js";
import Profile from "../Profile/Profile.js";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../Context.js";
import Dropdown from "../Dropdown/Dropdown.js";

const Navbar = ({
  alt: alternativeChosen,
  float: floatChosen,
  base: baseChosen,
}) => {
  const navigate = useNavigate();
  const user = useAuth();
  const [isAtTop, setIsAtTop] = useState(true);
  const [scrollingUp, setScrollingUp] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsAtTop(currentScrollY < 800);
      setScrollingUp(currentScrollY < prevScrollY);
      setPrevScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollY]);

  const renderButtons = () => {
    if (!baseChosen) {
      return (
        <div className={"Navbar-button-container"}>
          <Dropdown
            buttonText="more"
            dropdownContent={
              <div>
                <Link to="/manifesto">learn more</Link>
                <Link to="/contactus">contact us</Link>
              </div>
            }
            alt={alternativeChosen}
          />
          {user ? (
            <Profile alt={alternativeChosen} />
          ) : (
            <Button
              alt={alternativeChosen}
              dropdown={false}
              large={false}
              long={true}
              text="log in / sign up"
              onClick={() => navigate("/login")}
            />
          )}
        </div>
      );
    }
  };

  return (
    <div
      className={`${
        floatChosen
          ? isAtTop
            ? "slide-out"
            : scrollingUp
            ? "slide-in"
            : "slide-out"
          : ""
      } ${
        alternativeChosen
          ? floatChosen
            ? "Navbar-container-alt"
            : "Navbar-container-fixed-alt"
          : floatChosen
          ? "Navbar-container"
          : "Navbar-container-fixed"
      }`}
    >
      <div className={"Navbar-content"}>
        <Logo alt={alternativeChosen} />
        {renderButtons()}
        <div className={"Navbar-mobile-dropdown"}>
          <Dropdown
            buttonText="more"
            dropdownContent={
              <div>
                <Link to="/login"> log in / sign up </Link>
                <Link to="/manifesto">learn more</Link>
                <Link to="/contactus">contact us</Link>
              </div>
            }
            alt={alternativeChosen}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
