import React, { useState } from "react";
import styles from "./SignUp.module.css";
import Navbar from "../../components/NavBar/Navbar.js";
import Button from "../../components/Button/Button.js";
import TextField from "../../components/TextField/TextField.js";
import { auth } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const initialFormState = {
  email: "",
  password: "",
  confirmPassword: "",
};

const SignUp = () => {
  const navigate = useNavigate();

  const [formState, setFormState] = useState(initialFormState);
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  const signUpWithEmailAndPassword = async () => {
    if (formState.password !== formState.confirmPassword) {
      setErrorMessage("passwords do not match");
    } else {
      try {
        await createUserWithEmailAndPassword(
          auth,
          formState.email,
          formState.password
        );
        navigate("/chefsearch");
      } catch (err) {
        if (err.code === "auth/email-already-in-use") {
          setErrorMessage("email already in use");
        } else if (err.code === "auth/invalid-email") {
          setErrorMessage("invalid email");
        } else if (err.code === "auth/weak-password") {
          setErrorMessage("weak password");
        }
      }
    }
  };

  return (
    <div className={styles.page}>
      <Navbar alt={false} float={false} base={true} />
      <div className={styles.signupContainer}>
        <h1 className={styles.banner}>create an account</h1>
        <div className={styles.form}>
          <TextField
            required
            label="email"
            type="email"
            value={formState.email}
            onChange={handleInputChange}
            name="email"
            error={
              errorMessage === "email already in use" ||
              errorMessage === "invalid email"
                ? true
                : false
            }
          />
          <TextField
            required
            label="password"
            type="password"
            value={formState.password}
            onChange={handleInputChange}
            name="password"
            error={
              errorMessage === "passwords do not match" ||
              errorMessage === "weak password"
                ? true
                : false
            }
          />
          <TextField
            required
            label="confirm password"
            type="password"
            value={formState.confirmPassword}
            onChange={handleInputChange}
            name="confirmPassword"
            error={
              errorMessage === "passwords do not match" ||
              errorMessage === "weak password"
                ? true
                : false
            }
          />
          {errorMessage && <p className={styles.error}>{errorMessage}</p>}
          <div className={styles.Button}>
            <Button
              large
              onClick={signUpWithEmailAndPassword}
              text="sign up"
            ></Button>
          </div>
        </div>

        <div className={styles.signUpText}>
          <p>
            already have an account?{" "}
            <a onClick={() => navigate("/login")}>log in here!</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
