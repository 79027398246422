import React, { useState } from "react";
import styles from "./NumberButton.module.css";

const NumberButton = (props) => {
  let defaultStyle = { background: "rgba(54, 114, 39, 0.17)" };
  let hoverStyle = { background: "rgba(54, 114, 39, 0.30)" };

  const [style, setStyle] = useState(defaultStyle);

  return (
    <div className={styles.container}>
      <button
        type="button"
        onMouseOver={() => setStyle(hoverStyle)}
        onMouseOut={() => setStyle(defaultStyle)}
        onClick={() => props.onClick()}
        style={style}
        className={props.isClicked ? styles.clicked : styles.default}
      >
        {props.number}
      </button>
    </div>
  );
};

export default NumberButton;
