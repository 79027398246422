import React from "react";
import left from "./assets/left.svg";
import right from "./assets/right.svg";
import left_alt from "./assets/left_alt.svg";
import right_alt from "./assets/right_alt.svg";
import left_large from "./assets/left_large.svg";
import right_large from "./assets/right_large.svg";
import left_large_alt from "./assets/left_large_alt.svg";
import right_large_alt from "./assets/right_large_alt.svg";

function Arrow(props) {
  const arrowMap = {
    left: left,
    right: right,
    left_alt: left_alt,
    right_alt: right_alt,
    left_large: left_large,
    right_large: right_large,
    left_large_alt: left_large_alt,
    right_large_alt: right_large_alt,
  };

  const { showLeft, alt, large } = props;
  const arrowKey = `${showLeft ? "left" : "right"}${large ? "_large" : ""}${
    alt ? "_alt" : ""
  }`;

  const display = arrowMap[arrowKey];

  return (
    <>
      <img
        src={display}
        alt={display}
        onClick={props.onClick}
        style={{ cursor: "pointer" }}
      />
    </>
  );
}

export default Arrow;
