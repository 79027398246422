import React from "react";
import styles from "./Manifesto.module.css";
import Navbar from "../../components/NavBar/Navbar.js";
import Footer from "../../components/Footer/Footer.js";
import blob from "./assets/blob.svg";
import ivan from "./assets/Ivan.png";
import julian from "./assets/Julian.png";
import ken from "./assets/Ken.png";
import olivia from "./assets/Olivia.png";
import owen from "./assets/Owen.png";
import paulina from "./assets/Paulina.png";
import phil from "./assets/Phil.png";
import wesley from "./assets/Wesley.png";
import aryha from "./assets/Aryha.png";
import mail from "./assets/Mail.png";
import linkedin from "./assets/LinkedIn.png";

const Manifesto = () => {
  return (
    <div className={styles.page}>
      <Navbar alt={true} float={false} base={false} />
      <Navbar alt={true} float={true} base={false} />
      <div className={styles.title}>
        <h1>we are a private dining collective.</h1>
      </div>
      <div className={styles.sub}>
        <h2>the beginning of first hellos and everlasting friendships</h2>
        <p>
          At Basil, we embrace a unique manifesto that guides every aspect of
          our service. We believe in the power of creating extraordinary
          culinary experiences, tailored to the individual desires and
          preferences of our guests. Our mission is to foster an intimate and
          exclusive atmosphere, where moments of connection, celebration, and
          indulgence come to life. We are passionate about sourcing the finest
          ingredients, working with talented chefs who are dedicated to their
          craft, and curating stunning venues that evoke a sense of elegance and
          sophistication. We prioritize exceptional service, ensuring that each
          guest feels cared for and attended to with personalized attention.
          Moreover, we are committed to sustainability, promoting ethical
          practices in our operations and supporting local communities. With our
          platform, we strive to transcend ordinary dining and redefine what it
          means to truly savor moments of culinary delight in an exclusive
          setting. Welcome to a world where gastronomic dreams become a reality.
        </p>
      </div>
      <div className={styles.sub}>
        <h2>a unique culinary journey </h2>
        <p>
          Owen’s journey in search of the perfect dining platform was an
          exploration of passion, creativity, and innovation. Driven by a
          relentless desire to share culinary artistry with the world. From
          bustling city streets to serene countryside retreats, Owen tirelessly
          sought a space that mirrored his culinary vision. Discovering that
          chefs craved a platform that would not only showcase their gastronomic
          expertise but also provide a market to offer their goods and services.
          After numerous encounters and heartfelt connections, Owen’s journey
          eventually led them to a private dining platform. A space where their
          culinary voice could echo and be the beginning of culinary dreams.
        </p>
      </div>
      <div className={styles.myteam}>
        <img src={blob} alt="blob" className={styles.blob} />
        <h1>the brigade</h1>
        <div className={styles.content}>
          <div className={styles.inside}>
            <img src={owen} alt="owen" className={styles.potrait} />
            <p className={styles.name}>Owen Purnomo</p>
            <p className={styles.role}>founder</p>
            <p>
              <a href="mailto:opurnomo@bu.edu">
                <img src={mail} alt="mail" className={styles.mail} />
              </a>
              <a
                href="https://www.linkedin.com/in/owenpurnomo/"
                target="_blank"
              >
                <img
                  src={linkedin}
                  alt="linkedin"
                  className={styles.linkedin}
                />
              </a>
            </p>
          </div>
          <div className={styles.inside}>
            <img src={phil} alt="phil" className={styles.potrait} />
            <p className={styles.name}>Phillip Budiman</p>
            <p className={styles.role}>head of software development</p>
            <p>
              <a href="mailto:pbudiman@bu.edu">
                <img src={mail} alt="mail" className={styles.mail} />
              </a>
              <a
                href="https://www.linkedin.com/in/phillipbudiman/"
                target="_blank"
              >
                <img
                  src={linkedin}
                  alt="linkedin"
                  className={styles.linkedin}
                />
              </a>
            </p>
          </div>
          <div className={styles.inside}>
            <img src={ivan} alt="ivan" className={styles.potrait} />
            <p className={styles.name}>Ivan The</p>
            <p className={styles.role}>head of ui/ux design</p>
            <p>
              <a href="mailto:ivmatt@bu.edu">
                <img src={mail} alt="mail" className={styles.mail} />
              </a>
              <a href="https://www.linkedin.com/in/ivmatt/" target="_blank">
                <img
                  src={linkedin}
                  alt="linkedin"
                  className={styles.linkedin}
                />
              </a>
            </p>
          </div>
          <div className={styles.inside}>
            <img src={paulina} alt="paulina" className={styles.potrait} />
            <p className={styles.name}>Paulina Preciat</p>
            <p className={styles.role}>head of social media and operations</p>
            <p>
              <a href="mailto:ppj@bu.edu">
                <img src={mail} alt="mail" className={styles.mail} />
              </a>
              <a
                href="https://www.linkedin.com/in/paulina-preciat-jasso-/"
                target="_blank"
              >
                <img
                  src={linkedin}
                  alt="linkedin"
                  className={styles.linkedin}
                />
              </a>
            </p>
          </div>
        </div>
        <div className={styles.content2}>
          <div className={styles.inside}>
            <img src={wesley} alt="wesley" className={styles.potrait} />
            <p className={styles.name}>Wesley Koe</p>
            <p className={styles.role}>strategic advisor</p>
            <p>
              <a href="mailto:weskoe@bu.edu">
                <img src={mail} alt="mail" className={styles.mail} />
              </a>
              <a href="https://www.linkedin.com/in/wesleykoe/" target="_blank">
                <img
                  src={linkedin}
                  alt="linkedin"
                  className={styles.linkedin}
                />
              </a>
            </p>
          </div>
          <div className={styles.inside}>
            <img src={olivia} alt="olivia" className={styles.potrait} />
            <p className={styles.name}>Olivia Hur</p>
            <p className={styles.role}>social media and content designer</p>
            <p>
              <a href="mailto:ohur@bu.edu">
                <img src={mail} alt="mail" className={styles.mail} />
              </a>
              <a href="https://www.linkedin.com/in/olivia-hur/" target="_blank">
                <img
                  src={linkedin}
                  alt="linkedin"
                  className={styles.linkedin}
                />
              </a>
            </p>
          </div>
          <div className={styles.inside}>
            <img src={ken} alt="ken" className={styles.potrait} />
            <p className={styles.name}>Kenneth Thomson</p>
            <p className={styles.role}>software engineer</p>
            <div className={styles.socials}>
              <p>
                <a href="mailto:ken1212@bu.edu">
                  <img src={mail} alt="mail" className={styles.mail} />
                </a>
                <a
                  href="https://www.linkedin.com/company/dinebasilinc/"
                  target="_blank"
                >
                  <img
                    src={linkedin}
                    alt="linkedin"
                    className={styles.linkedin}
                  />
                </a>
              </p>
            </div>
          </div>
          <div className={styles.inside}>
            <img src={julian} alt="julian" className={styles.potrait} />
            <p className={styles.name}>Julian Tanja</p>
            <p className={styles.role}>software engineer</p>
            <p>
              <a href="mailto:jrtanja@bu.edu">
                <img src={mail} alt="mail" className={styles.mail} />
              </a>
              <a
                href="https://www.linkedin.com/in/juliantanja/"
                target="_blank"
              >
                <img
                  src={linkedin}
                  alt="linkedin"
                  className={styles.linkedin}
                />
              </a>
            </p>
          </div>

          <div className={styles.inside}>
            <img src={aryha} alt="aryha" className={styles.potrait} />
            <p className={styles.name}>Aryha Ashaari</p>
            <p className={styles.role}>software engineer</p>
            <p>
              <a href="mailto:aashaari@bu.edu">
                <img src={mail} alt="mail" className={styles.mail} />
              </a>
              <a
                href="https://www.linkedin.com/in/aryhaashaari/"
                target="_blank"
              >
                <img
                  src={linkedin}
                  alt="linkedin"
                  className={styles.linkedin}
                />
              </a>
            </p>
          </div>
        </div>
      </div>
      <div>
        <Footer alt />
      </div>
    </div>
  );
};

export default Manifesto;
