import React from "react";
import styles from "./ErrorPage.module.css";
import Button from "../../components/Button/Button.js";
import Navbar from "../../components/NavBar/Navbar.js";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.page}>
      <Navbar alt={false} float={false} base={false} />
      <div className={styles.title}>
        <h1>404</h1>
      </div>
      <div className={styles.sub}>
        <h2>Something went wrong.</h2>
        <p>
          The page you are looking for may have been moved, deleted, or possibly
          never existed. Don't worry though,{" "}
          <b>your basil dinner still awaits!</b>
        </p>
        <br></br>
        <div className={styles.button}>
          <Button
            text="back to home"
            long
            large
            onClick={() => navigate("/")}
            dropdown={false}
            alt={false}
          ></Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
