import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Styles from "./Button.module.css";

import dropdown_small from "./assets/dropdown_small.svg";
import dropdown_small_alt from "./assets/dropdown_small_alt.svg";
import dropdown_large from "./assets/dropdown_large.svg";
import dropdown_large_alt from "./assets/dropdown_large_alt.svg";

const Button = (props) => {
  // Renaming the props to make readable code
  let textOnButton = props.text;
  let alternativeChosen = props.alt;
  let largeChosen = props.large;
  let dropdownChosen = props.dropdown;
  let longChosen = props.long;

  // Initializing default values for styling and React hooks
  let defaultStyle = { background: "rgba(54, 114, 39, 0.17)" };
  let hoverStyle = { background: "rgba(54, 114, 39, 0.30)" };
  let largeDropdown = dropdown_large;
  let smallDropdown = dropdown_small;
  let dropdownSource = smallDropdown;
  let dropdownArrow;
  let dropdownBox;

  // Initializing React hooks used for dynamic button and dropdown box styling
  const dropdownRef = useRef(null);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [visibility, setVisibility] = useState({ visibility: "hidden" });
  const dropdownToggle = () => {
    setDropdownActive(!dropdownActive);
    if (dropdownActive) {
      setVisibility({ visibility: "visible" });
    } else {
      setVisibility({ visibility: "hidden" });
    }
  };

  // Conditionals for styling
  if (alternativeChosen) {
    smallDropdown = dropdown_small_alt;
    largeDropdown = dropdown_large_alt;
    dropdownSource = smallDropdown;
    defaultStyle = {
      background: "rgba(243, 236, 209, 0.17)",
      color: "rgba(243, 236, 209, 1)",
    };
    hoverStyle = {
      background: "rgba(243, 236, 209, 0.3)",
      color: "rgba(243, 236, 209, 1)",
    };
  }
  if (largeChosen) {
    dropdownSource = largeDropdown;
    defaultStyle = {
      ...defaultStyle,
      width: "11.25rem",
      height: "2.75rem",
      fontSize: "1.5rem",
    };
    hoverStyle = {
      ...hoverStyle,
      width: "11.25rem",
      height: "2.75rem",
      fontSize: "1.5rem",
    };
  }
  if (longChosen) {
    largeChosen
      ? (defaultStyle = { ...defaultStyle, width: "15rem" })
      : (defaultStyle = { ...defaultStyle, width: "11.25rem" });
    largeChosen
      ? (hoverStyle = { ...hoverStyle, width: "15rem" })
      : (hoverStyle = { ...hoverStyle, width: "11.25rem" });
  }
  if (dropdownChosen) {
    dropdownArrow = <img src={dropdownSource} alt="Dropdown" />;
    dropdownBox = (
      <nav
        ref={dropdownRef}
        className={alternativeChosen ? Styles.dropdown_alt : Styles.dropdown}
        style={visibility}
      >
        <div className={alternativeChosen ? Styles.links_alt : Styles.links}>
          <Link to="/manifesto">learn more</Link>
          <Link to="/contactus">contact us</Link>
          <a>faq</a>
        </div>
      </nav>
    );
  } else {
    dropdownArrow = null;
  }

  const [style, setStyle] = useState(defaultStyle);

  return (
    <div className={Styles.container}>
      <button
        type="button"
        onMouseEnter={() => setStyle(hoverStyle)}
        onMouseLeave={() => setStyle(defaultStyle)}
        onClick={dropdownChosen ? () => dropdownToggle() : props.onClick}
        style={style}
        className={Styles.button}
      >
        {textOnButton}
        {dropdownArrow}
      </button>
      {dropdownBox}
    </div>
  );
};

export default Button;
