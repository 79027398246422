import React from "react";
import styles from "./Landing.module.css";
import { useNavigate } from "react-router-dom";

import Navbar from "../../components/NavBar/Navbar.js";
import Footer from "../../components/Footer/Footer.js";
import Button from "../../components/Button/Button.js";
import InfiniteLoop from "../../components/InfiniteLoop/InfiniteLoop.js";

import CardBlob from "./assets/CardBlob.svg";
import ListBlob from "./assets/ListBlob.svg";
import WaveTop from "./assets/wave-top.svg";
import WaveBottom from "./assets/wave-bottom.svg";
import divider from "./assets/divider.svg";
import Card from "./assets/CommunityCardstest.png";
import Bean from "./assets/Bean.svg";
import Food from "./assets/FoodBar.png";
import Title from "./assets/Title.svg";
import Community from "./assets/Community.svg";
import CommunityAlt from "./assets/CommunityAlt.svg";
import Own from "./assets/Own.svg";
import ChefCard from "./assets/ChefCard.png";
import WorryFree from "./assets/WorryFree.svg";
import Safe from "./assets/Safe.svg";

const Landing = () => {
  const navigate = useNavigate();
  const handleLearnMoreButton = () => {
    navigate("/manifesto");
    window.scroll(0, 0);
  };
  return (
    <div className={styles.page}>
      <div className={styles.navbar}>
        <Navbar alt={false} float={false} base={false} />
        <Navbar alt={false} float={true} base={false} />
      </div>
      <div className={styles.banner}>
        <img src={Title} alt={Title} />
        <div className={styles.simple}>
          <span>made </span>
          <span className={styles.slidingVetical}>
            <span className={styles.slidingVerticalPath}>simple</span>
            <span className={styles.slidingVerticalPath}>casual</span>
            <span className={styles.slidingVerticalPath}>simple</span>
            <span className={styles.slidingVerticalPath}>casual</span>
          </span>
          <span className={styles.shadow}>simple</span>.
        </div>
        <div className={styles.buttonContainer}>
          <Button
            text="become a chef"
            long
            large
            onClick={() => navigate("/contactus")}
          ></Button>
          <Button
            text="browse chefs"
            long
            large
            onClick={() => navigate("/chefsearch")}
          ></Button>
        </div>
      </div>
      <div
        style={{
          height: "200vh",
          maxHeight: "82rem",
          position: "absolute",
          display: "flex",
          alignItems: "flex-end",
        }}
      ></div>
      <div className={styles.infiniteloop}>
        <InfiniteLoop speed={80} direction={"right"}>
          <img src={Food} className={styles.foodBar} />
          <p style={{ color: "var(--color-eggshell)" }}>h</p>
          <img src={Food} className={styles.foodBar} />
        </InfiniteLoop>
      </div>
      <div className={styles.convenience}>
        <img src={WaveTop} style={{ width: "100vw" }} />

        <div className={styles.convenienceText}>
          <h2>how it works?</h2>
          <img src={divider} alt="divider" style={{ margin: "4.5rem" }} />
          <div className={styles.convenienceList}>
            <p>1. choose your chef</p>
            <p
              className={styles.crossed}
              style={{
                textDecoration: "line-through",
                color: "rgba(243, 236, 209, 0.17)",
                marginLeft: "2.5rem",
              }}
            >
              send an email
            </p>
            <p>2. pick a date</p>
            <p
              className={styles.crossed}
              style={{
                textDecoration: "line-through",
                color: "rgba(243, 236, 209, 0.17)",
                marginLeft: "2.5rem",
              }}
            >
              discuss availability
            </p>
            <p>3. dine</p>
          </div>
        </div>
        <img src={WaveBottom} style={{ width: "100vw" }} />
      </div>
      <div className={styles.community}>
        <img src={Community} alt={Community} className={styles.communityText} />
        <img
          src={CommunityAlt}
          alt={CommunityAlt}
          className={styles.communityAlt}
        />
        <div className={styles.communityCardContainer}>
          <img src={Card} />
          <img src={Card} />
          <img src={Card} />
          <img src={Card} />
          <img src={Card} />
          <img src={Card} />
        </div>
      </div>
      <div className={styles.own}>
        <div className={styles.ownText}>
          <img src={Own} alt={Own} />
        </div>
        <div className={styles.cardAndBlob} style={{ position: "relative" }}>
          <img src={CardBlob} alt={CardBlob} />
          <img
            src={ChefCard}
            alt={ChefCard}
            style={{
              position: "absolute",
              width: "25rem",
              top: "0",
              left: "4rem",
            }}
          />
        </div>
      </div>
      <div className={styles.worryFree}>
        <div className={styles.worryFreeText}>
          <img src={WorryFree} />
        </div>
        <img src={ListBlob} />
      </div>
      <div className={styles.safe}>
        <img src={Safe} style={{ width: "38rem" }} />
      </div>
      <div className={styles.safeBean}>
        <img src={Bean} style={{ width: "95%" }} />
      </div>
      <div className={styles.banner2}>
        <h1>
          so when is your <br />
          basil <span style={{ color: "var(--color-flame)" }}>
            {" "}
            dinner?
          </span>{" "}
        </h1>
        <div className={styles.buttonContainer}>
          <Button
            text="learn more"
            long
            large
            onClick={handleLearnMoreButton}
          ></Button>
        </div>
      </div>

      <div style={{ position: "absolute", bottom: "0px", width: "100%" }}>
        <Footer />
      </div>
    </div>
  );
};

export default Landing;
