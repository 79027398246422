import React from "react";
import styles from "./Footer.module.css";
import Logo from "../Logo/Logo";

const Footer = (props) => {
  // Renaming and declaring values for readability
  let alternativeChosen = props.alt;
  let sap = "var(--color-sap)";
  let eggshell = "var(--color-eggshell)";
  let textColor = alternativeChosen ? sap : eggshell;
  let textStyle = { color: textColor };
  let backgroundShape = (
    <svg
      width="100%"
      viewBox="0 0 1400 4307"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 195L525.602 30.3625C653.133 -9.585 789.842 -9.50357 917.325 30.5957L1440 195V1252.5H0V195Z"
        fill={alternativeChosen ? eggshell : sap}
      />
    </svg>
  );

  return (
    <div className={styles.Footer}>
      <div className={styles.shape} data-testid="Footer">
        {backgroundShape}
      </div>
      <div className={styles.text}>
        <Logo alt={!alternativeChosen} />
        <div className={styles.links} style={textStyle}>
          <div style={{ left: "3.25rem" }}>
            <a>twitter</a>
            <a>instagram</a>
            <a>tiktok</a>
          </div>
          <div style={{ right: "3.25rem" }}>
            <a>privacy</a>
            <a>contact us</a>
            <a>faq</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
