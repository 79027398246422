import React from "react";
import styles from "./Menu.module.css";

const Menu = (props) => {
  const menu = props.menuText;
  const price = props.pricePerPerson;
  const menuPic = props.menuPic;

  return (
    <>
      {props.isMenuText ? (
        <div
          className={styles.menuContainer}
          style={{ border: "3px solid black" }}
        >
          <div style={{ textAlign: "center" }}>
            <h1 className={styles.menuHeader}>menu</h1>
            {Object.keys(menu).map((subheader) => (
              <>
                <p className={styles.menuSubheaders}>{subheader}</p>
                {menu[subheader].map((dish) => (
                  <p className={styles.menuText} key={dish}>
                    {dish}
                  </p>
                ))}
              </>
            ))}
            <hr className={styles.menuLine} />
            <p className={styles.menuText}>
              <span style={{ fontWeight: "500" }}>${price}</span> per person
            </p>
          </div>
        </div>
      ) : (
        <div
          className={styles.menuContainer}
          style={{ backgroundImage: `url(${menuPic})` }}
        ></div>
      )}
    </>
  );
};

export default Menu;
