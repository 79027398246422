import React, { useState } from "react";
import next from "./assets/next.svg";
import nextHover from "./assets/nextHover.svg";

const NextButton = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div>
      <img
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
        src={isHovered ? nextHover : next}
        alt="next"
        onClick={props.onClick}
      />
    </div>
  );
};

export default NextButton;
