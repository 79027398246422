import React, { useState, useEffect } from "react";
import styles from "./ContactUs.module.css";
import { db } from "../../firebase.js";
import { collection, addDoc } from "firebase/firestore";
import ReCAPTCHA from "react-google-recaptcha";

import Button from "../../components/Button/Button.js";
import TextField from "../../components/TextField/TextField.js";
import TextArea from "../../components/TextArea/TextArea.js";
import Navbar from "../../components/NavBar/Navbar.js";

const initialFormState = {
  email: "",
  subject: "",
  message: "",
};

const ContactUs = () => {
  const [formState, setFormState] = useState(initialFormState);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [renderMobile, setRenderMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 750) {
        setRenderMobile(true);
      } else {
        setRenderMobile(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = async () => {
    const { email, subject, message } = formState;

    if (email === "" || subject === "" || message === "") {
      setErrorMessage("please fill in all the fields.");
    } else if (!recaptchaToken) {
      setErrorMessage("please complete the captcha.");
    } else {
      const mailRef = collection(db, "NotificationEmails");

      const emailObject = {
        message: {
          subject: subject,
          text: `${message}\n\n email sent from: ${email}`,
        },
        to: "contact@dinebasil.com",
      };

      try {
        await addDoc(mailRef, emailObject);
        setMessage("email successfully sent.");
        setErrorMessage("");
        setFormState({ email: "", subject: "", message: "" });
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className={styles.page}>
      <Navbar></Navbar>
      <div className={styles.container}>
        <div className={styles.banner}>
          <h2>have a question?</h2>
          <h1>let us know!</h1>
        </div>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.formheader}>
            <TextField
              required
              label="email"
              type="email"
              value={formState.email}
              onChange={handleInputChange}
              name="email"
              error={!!errorMessage}
            />
            <TextField
              required
              label="subject"
              type="string"
              value={formState.subject}
              onChange={handleInputChange}
              name="subject"
              error={!!errorMessage}
            />
          </div>
          {!renderMobile && (
            <TextArea
              required
              cols="69"
              placeholder="your message"
              value={formState.message}
              onChange={handleInputChange}
              name="message"
              error={!!errorMessage}
            />
          )}

          {renderMobile && (
            <TextArea
              required
              cols="32.5"
              placeholder="your message"
              value={formState.message}
              onChange={handleInputChange}
              name="message"
              error={!!errorMessage}
            />
          )}

          <ReCAPTCHA
            sitekey="6LeIOtInAAAAAELFDYDWSan3Q81yeZEW-Cu3J7wc"
            onChange={(token) => {
              setRecaptchaToken(token);
            }}
          />
          {errorMessage && <p className={styles.error}>{errorMessage}</p>}
          {message && <p className={styles.success}>{message}</p>}
          <div className={styles.Button}>
            <Button onClick={handleSubmit} text="send"></Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
