import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Login.module.css";
import Navbar from "../../components/NavBar/Navbar.js";
import Button from "../../components/Button/Button.js";
import TextField from "../../components/TextField/TextField.js";
import google from "./assets/google.svg";
import { auth, googleProvider } from "../../firebase";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";

const initialFormState = {
  email: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();

  const [formState, setFormState] = useState(initialFormState);
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  const logInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      navigate("/chefsearch");
    } catch (err) {
      console.log(err);
    }
  };

  const logInWithEmailAndPassword = async () => {
    try {
      await signInWithEmailAndPassword(
        auth,
        formState.email,
        formState.password
      );
      navigate("/chefsearch");
    } catch (err) {
      if (err.code === "auth/user-not-found") {
        setErrorMessage("user does not exist");
      } else if (
        err.code === "auth/invalid-email" ||
        err.code === "auth/wrong-password"
      ) {
        setErrorMessage("invalid email or password");
      } else {
        console.log(err.code);
      }
    }
  };

  return (
    <div className={styles.page}>
      <Navbar alt={false} float={false} base={true} />
      <div className={styles.loginContainer}>
        <h1 className={styles.banner}>welcome <br className={styles.br}></br>to basil</h1>
        <div className={styles.social}>
          <img src={google} onClick={logInWithGoogle} alt="google" />
        </div>
        <div className={styles.divider}>
          <div className={styles.line}></div>
          <p className={styles.loginText}>or</p>
          <div className={styles.line}></div>
        </div>
        <div className={styles.form}>
          <TextField
            required
            label="email"
            type="email"
            value={formState.email}
            onChange={handleInputChange}
            name="email"
            error={errorMessage}
          />
          <TextField
            required
            label="password"
            type="password"
            value={formState.password}
            onChange={handleInputChange}
            name="password"
            error={errorMessage}
          />
          {errorMessage && <p className={styles.error}>{errorMessage}</p>}
          <div className={styles.Button}>
            <Button
              large
              onClick={logInWithEmailAndPassword}
              text="login"
            ></Button>
          </div>
        </div>
        <div className={styles.loginText}>
          <p>
            don't have an account?
            <a onClick={() => navigate("/signup")}> sign up with email!</a>
          </p>
          <p>
            can't log in?
            <a onClick={() => navigate("/resetpassword")}> reset your password!</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
