import React, { useState } from "react";
import styles from "./UploadFile.module.css";

import Button from "../Button/Button";

import { collection, getDocs, query, where, setDoc } from "firebase/firestore";
import { db } from "../../firebase.js";
import { storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";

const UploadFile = (props) => {
  const [imageUpload, setImageUpload] = useState(null);
  const [hasUploaded, setHasUploaded] = useState(false);

  const uploadFile = async () => {
    if (imageUpload == null) return;
    const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);

    try {
      const chefInfo = await getDocs(
        query(collection(db, "Chefs"), where("email", "==", props.email))
      );
      const snapshot = await uploadBytes(imageRef, imageUpload);
      const url = await getDownloadURL(snapshot.ref);
      await setDoc(
        chefInfo.docs[0].ref,
        { [props.value]: url },
        { merge: true }
      );

      setHasUploaded(true);
      window.alert("Your image has been uploaded");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={styles.fileInput}>
      <label className={styles.LabelText}>{props.label}</label>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          background: "white",
          padding: "0.5rem",
          borderRadius: "100px",
        }}
      >
        {!hasUploaded ? (
          <>
            <input
              type="file"
              onChange={(event) => {
                setImageUpload(event.target.files[0]);
              }}
            />
            <Button onClick={uploadFile} text="upload"></Button>
          </>
        ) : (
          <p style={{ padding: "0.5rem" }}>{imageUpload.name}</p>
        )}
      </div>
    </div>
  );
};

export default UploadFile;
