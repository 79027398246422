import React, { useState } from "react";
import styles from "./ResetPassword.module.css";
import { auth } from "../../firebase.js";
import { sendPasswordResetEmail } from "firebase/auth";

import Navbar from "../../components/NavBar/Navbar.js";
import Button from "../../components/Button/Button.js";
import TextField from "../../components/TextField/TextField.js";

const initialFormState = {
  email: "",
};

const ResetPassword = () => {
  const [formState, setFormState] = useState(initialFormState);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      await sendPasswordResetEmail(auth, formState.email);
      setMessage("password reset email sent. please check your inbox.");
    } catch (err) {
      console.log(err);
      setErrorMessage("please fill in the field.");
    }
  };

  return (
    <div className={styles.page}>
      <Navbar alt={false} float={false} base={true} />
      <div className={styles.container}>
        <h1 className={styles.banner}>reset password</h1>
        {!message && (
          <div className={styles.form}>
            <div className={styles.loginText}>
              enter the email associated with your account
            </div>
            <TextField
              required
              type="email"
              value={formState.email}
              onChange={handleInputChange}
              name="email"
              error={errorMessage ? true : false}
            />
            {errorMessage && <p className={styles.error}>{errorMessage}</p>}
            <div className={styles.Button}>
              <Button large text="reset" onClick={handleSubmit}></Button>
            </div>
          </div>
        )}
        {message && <p className={styles.success}>{message}</p>}
      </div>
    </div>
  );
};

export default ResetPassword;
